import { Button } from '@finn/atoms';
import { ModalContainer, ModalKey, useOpenModal } from '@finn/ua-modals';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { Recaptcha } from '@finn/ui-components';
import { isMobileApp, useCurrentLocale } from '@finn/ui-utils';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ContinueButton from '~/components/Checkout/ContinueButton';
import { useTrackScreenSwitching } from '~/modules/login/hooks/useTrackScreenSwitching';

import { BusinessDetectedModal } from './BusinessDetectedModal';
import { EmailField } from './EmailField';
import { ExtendedAccountFields } from './ExtendedAccountFields';
import { useLoginText } from './hooks/useLoginText';
import { useSubmitHandler } from './hooks/useSubmitHandler';
import { PasswordField } from './PasswordField';
import { SocialLoginButtons } from './SocialLoginButtons';
import { LoginScreen, useLoginStore } from './store/useLoginStore';
import { SwitchToLogin } from './SwitchToLogin';

const MAX_ALLOWED_MAGIC_LINK_SUBMISSION = 3;

export const LoginForm: React.FC<{
  isLoadingRestore?: boolean;
}> = ({ isLoadingRestore }) => {
  useTrackScreenSwitching();
  const { isDE } = useCurrentLocale();
  const loginScreen = useLoginStore((state) => state.loginScreen);
  const setLoginScreen = useLoginStore((state) => state.setLoginScreen);
  const hasBusinessAccount = useLoginStore((state) => state.hasBusinessAccount);
  const magicLinkSentCount = useLoginStore((state) => state.magicLinkSentCount);
  const serverErrorCode = useLoginStore((state) => state.serverErrorCode);
  const setHasBusinessAccount = useLoginStore(
    (state) => state.setHasBusinessAccount
  );
  const track = useTrackingStore((state) => state.track);
  const resetTrackedCount = useTrackingStore(
    (state) => state.resetTrackedCount
  );

  const shouldHideCta =
    magicLinkSentCount >= MAX_ALLOWED_MAGIC_LINK_SUBMISSION &&
    [LoginScreen.magicLinkSuccess, LoginScreen.forgotPasswordSuccess].includes(
      loginScreen
    );
  const form = useFormContext();
  const submitHandler = useSubmitHandler();
  const errorHandler = useCallback(
    (error) => {
      track(TrackingEventName.FORM_VALIDATION_ERROR, {
        location: loginScreen,
        additionalProps: { error },
      });
    },
    [loginScreen, track]
  );
  const openModal = useOpenModal();
  const email = form.watch('email');
  useEffect(() => {
    setHasBusinessAccount(email);
  }, [email, setHasBusinessAccount]);
  useEffect(() => {
    if (hasBusinessAccount) {
      track(TrackingEventName.BUSINESS_ACCOUNT_DETECTED, {
        location: loginScreen,
      });
      openModal(ModalKey.BUSINESS_ACCOUNT_DETECTED);
    }
  }, [hasBusinessAccount, openModal]);

  const [parentRef] = useAutoAnimate();

  const isInitialScreen = loginScreen === LoginScreen.emailCheckForm;
  const shouldShowLogin = loginScreen === LoginScreen.loginForm;
  const shouldShowRegistration = loginScreen === LoginScreen.registrationForm;
  const shouldShowPasswordField = !!(shouldShowLogin || shouldShowRegistration);
  const shouldShowBackButton = [
    LoginScreen.magicLinkForm,
    LoginScreen.magicLinkSuccess,
    LoginScreen.forgotPasswordForm,
    LoginScreen.forgotPasswordSuccess,
  ].includes(loginScreen);

  const loginText = useLoginText();

  const href = window.location.href;
  const isAppLoginScreen =
    isMobileApp() &&
    href.includes('mobile/auth') &&
    !href.includes('mobile/authv2');

  return (
    <>
      <form
        onSubmit={form.handleSubmit(submitHandler, errorHandler)}
        data-testid="login-registration-form"
      >
        <div translate="no" className="flex flex-col gap-4">
          <div ref={parentRef} className="flex flex-col gap-4">
            <h2 className="global-t5-semibold">{loginText.title}</h2>
            <p className="body-14-regular">{loginText.subtitle}</p>
            <EmailField label={loginText.emailLabel} />
            {shouldShowRegistration && <ExtendedAccountFields />}
            {shouldShowPasswordField && (
              <PasswordField label={loginText.forgotPasswordLabel} />
            )}
            {shouldShowRegistration && <Recaptcha />}
          </div>
          {serverErrorCode && (
            <p className="text-red body-14-light m-0">
              {loginText.errorMessage}
            </p>
          )}
          {!shouldHideCta && (
            <ContinueButton
              type="submit"
              onClick={() => {
                resetTrackedCount();
                track(TrackingEventName.CTA_CLICKED, { location: loginScreen });
              }}
              loading={form.formState.isSubmitting || isLoadingRestore}
              title={loginText.buttonLabel}
            />
          )}
          {shouldShowBackButton && (
            <Button
              label={loginText.backButtonLabel}
              variant="outlinedDark"
              fullWidth
              size="large"
              disabled={form.formState.isSubmitting}
              onClick={() => {
                resetTrackedCount();
                track(TrackingEventName.CTA_CLICKED, {
                  location: loginScreen,
                  additionalProps: { cta_type: 'secondary' },
                });
                setLoginScreen(LoginScreen.emailCheckForm);
              }}
            />
          )}
          <SwitchToLogin />
          {isInitialScreen && !isAppLoginScreen && isDE && (
            <SocialLoginButtons
              socialButtonSeparator={loginText.socialButtonSeparator}
            />
          )}
        </div>
      </form>
      <ModalContainer
        modalKey={ModalKey.BUSINESS_ACCOUNT_DETECTED}
        email={email}
        ModalComponent={BusinessDetectedModal}
      />
    </>
  );
};
