import { HookFormInput } from '@finn/ui-components';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { LoginScreen, useLoginStore } from './store/useLoginStore';

type Props = {
  label: string;
};

export const EmailField: React.FC<Props> = ({ label }) => {
  const form = useFormContext();
  const savedFormValues = useLoginStore((state) => state.formValues);
  const loginScreen = useLoginStore((state) => state.loginScreen);
  const setLoginScreen = useLoginStore((state) => state.setLoginScreen);
  const shouldShowLogin = loginScreen === LoginScreen.loginForm;
  const hiddenEmail = [
    LoginScreen.magicLinkSuccess,
    LoginScreen.forgotPasswordSuccess,
  ].includes(loginScreen);
  const email = form?.watch('email');
  const savedEmail = savedFormValues?.email;

  useEffect(() => {
    if (shouldShowLogin && email !== savedEmail) {
      setLoginScreen(LoginScreen.emailCheckForm);
    }
  }, [email, shouldShowLogin, savedEmail, setLoginScreen]);

  return hiddenEmail ? (
    <Controller
      control={form.control}
      name="email"
      render={({ field }) => (
        <input type="hidden" name="email" value={field?.value as string} />
      )}
    />
  ) : (
    <HookFormInput name="email" label={label} form={form} />
  );
};
