// we use global unsafe colors instead of tailwind
// because we need to style internal library components
import unsafeColors from '@finn/design-system/unsafe_colors';
import { makeStyles } from '@material-ui/core';

export const usePhoneInputStyle = makeStyles(() => ({
  root: {
    position: 'relative',
    '& .react-international-phone-country-selector': { position: 'relative' },
    '& .react-international-phone-country-selector-button': {
      display: 'flex',
      height: '56px',
      boxSizing: 'border-box',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0',
      border: `1px solid ${unsafeColors.pearl}`,
      margin: '0',
      appearance: 'button',
      WebkitAppearance: 'button',
      backgroundColor: unsafeColors.white,
      cursor: 'pointer',
      textTransform: 'none',
      userSelect: 'none',
    },
    '& .react-international-phone-country-selector-button:hover': {
      backgroundColor: unsafeColors.cotton,
    },
    '& .react-international-phone-country-selector-button--hide-dropdown': {
      cursor: 'auto',
    },
    '& .react-international-phone-country-selector-button--hide-dropdown:hover':
      {
        backgroundColor: 'transparent',
      },
    '& .react-international-phone-country-selector-button__button-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .react-international-phone-country-selector-button__flag-emoji': {
      margin: '0 4px',
    },
    '& .react-international-phone-country-selector-button__dropdown-arrow': {
      borderTop: `4px solid ${unsafeColors.black}`,
      borderRight: '4px solid transparent',
      borderLeft: '4px solid transparent',
      marginRight: '4px',
      marginLeft: '8px',
      transition: 'all 0.1s ease-out',
    },
    '& .react-international-phone-country-selector-button__dropdown-arrow--active':
      {
        transform: 'rotateX(180deg)',
      },
    '& .react-international-phone-flag-emoji': {
      width: '24px',
      height: '24px',
      boxSizing: 'border-box',
    },
    '& .react-international-phone-country-selector-dropdown': {
      position: 'absolute',
      zIndex: 9000,
      top: '52px',
      left: '0',
      display: 'flex',
      width: '300px',
      maxHeight: '320px',
      flexDirection: 'column',
      padding: '4px 0',
      margin: '0',
      backgroundColor: 'white',
      boxShadow: '2px 2px 16px rgba(0, 0, 0, 0.25)',
      color: unsafeColors.black,
      listStyle: 'none',
      overflowY: 'scroll',
      outline: 'none',
    },
    '& .react-international-phone-country-selector-dropdown__list-item': {
      display: 'flex',
      minHeight: '48px',
      boxSizing: 'border-box',
      alignItems: 'center',
      padding: '8px 12px',
    },
    '& .react-international-phone-country-selector-dropdown__list-item-flag-emoji':
      {
        marginRight: '8px',
      },
    '& .react-international-phone-country-selector-dropdown__list-item-country-name':
      {
        marginRight: '8px',
        fontSize: '14px',
      },
    '& .react-international-phone-country-selector-dropdown__list-item-dial-code':
      {
        color: 'gray',
        fontSize: '14px',
      },
    '& .react-international-phone-country-selector-dropdown__list-item:hover': {
      backgroundColor: unsafeColors.cotton,
      cursor: 'pointer',
    },
    '& .react-international-phone-country-selector-dropdown__list-item--selected,.react-international-phone-country-selector-dropdown__list-item--focused':
      {
        backgroundColor: unsafeColors.cotton,
        color: unsafeColors.black,
      },
    '& .react-international-phone-country-selector-dropdown__list-item--selected  .react-international-phone-country-selector-dropdown__list-item-dial-code, .react-international-phone-country-selector-dropdown__list-item--focused .react-international-phone-country-selector-dropdown__list-item-dial-code':
      {
        color: 'gray',
      },
    '& .react-international-phone-country-selector-dropdown__list-item--focused':
      {
        backgroundColor: unsafeColors.cotton,
      },
    '& .react-international-phone-dial-code-preview': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 8px',
      border: `1px solid ${unsafeColors.pearl}`,
      marginRight: '-1px',
      backgroundColor: 'white',
      color: unsafeColors.black,
      fontSize: '16px',
    },
    '& .react-international-phone-input-container': { display: 'flex' },
    '& .react-international-phone-input-container:focus-within': {
      boxShadow: `0 0 0 2px ${unsafeColors.black}`,
      borderRadius: '2px',
    },
    '& .react-international-phone-input-container  .react-international-phone-country-selector-button':
      {
        borderRadius: '2px',
        marginRight: '-1px',
        borderBottomRightRadius: '0',
        borderTopRightRadius: '0',
        padding: '0 8px',
      },
    '& .react-international-phone-input-container .react-international-phone-input':
      {
        overflow: 'visible',
        height: '56px',
        boxSizing: 'border-box',
        padding: '16px 8px 0 8px',
        border: `1px solid ${unsafeColors.pearl}`,
        borderRadius: '2px',
        margin: '0',
        backgroundColor: 'white',
        borderBottomLeftRadius: '0',
        borderTopLeftRadius: '0',
        color: unsafeColors.black,
        fontFamily: 'inherit',
        fontSize: '16px',
      },
    '& .react-international-phone-input-container  .react-international-phone-input:focus':
      {
        outline: 'none',
      },
  },
  label: {
    position: 'absolute',
    top: 8,
    left: 78,
    fontSize: '16px',
    fontWeight: 400,
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
  },
}));
