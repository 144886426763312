import { Button, ButtonLink } from '@finn/atoms';
import { Modal, ModalContent, ModalHeader } from '@finn/atoms/Modal';
import { useLoginFormTracker } from '@finn/ua-auth';
import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

type Props = { email: string; open: boolean; onClose(): void };

export const BusinessDetectedModal: React.FC<Props> = ({
  open,
  onClose,
  email,
}) => {
  const classes = useStyles();
  const i18n = useIntl();

  const { b2bLoginClicked } = useLoginFormTracker();

  const title = i18n.formatMessage({
    id: `userAccount.login.fleet_prompt.fleet.title`,
  });
  const body = i18n.formatMessage(
    {
      id: `userAccount.login.fleet_prompt.fleet.accountDetected`,
    },
    { email }
  );
  const linkHref = i18n.formatMessage({
    id: `userAccount.login.fleet_prompt.fleet.link_href`,
  });
  const linkText = i18n.formatMessage({
    id: `userAccount.login.fleet_prompt.fleet.link_text`,
  });
  const cancelText = i18n.formatMessage({
    id: `checkout.cancel`,
  });

  return (
    <Modal
      size="md"
      open={open}
      onClose={onClose}
      scrollAll
      disableBrowserHistory
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalContent>
        <div className={classes.root}>
          <p className="body-14-regular">{body}</p>
          <div>
            <ButtonLink
              label={linkText}
              variant="primary"
              size="large"
              href={linkHref}
              fullWidth
              onClick={b2bLoginClicked}
            />
          </div>
          <div>
            <Button
              variant="outlinedDark"
              label={cancelText}
              onClick={onClose}
              size="large"
              fullWidth
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
