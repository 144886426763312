import { CheckoutStep } from '@finn/ua-auth';
import { errorMessages } from '@finn/ua-constants';
import {
  ECCOMMERCE_EVENTS_PROPERTIES,
  TrackingEventName,
  useTrackingStore,
} from '@finn/ua-tracking';
import { checkoutErrorEvent } from '@finn/ui-modules';
import {
  CookieKeys,
  scrollToTopWithoutAnimation,
  setClientCookie,
  setClientJSONCookie,
  useCurrentLocale,
} from '@finn/ui-utils';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { submitLeadContact } from '~/components/Checkout/Contact/utils/utils';
import { goToNextCheckoutStep } from '~/components/Checkout/utils/routeHandler';
import CheckoutContext from '~/contexts/Checkout';
import { useGetDealData } from '~/modules/checkout/hooks/useGetDealData';
import { useGetDownPaymentInCart } from '~/modules/checkout/hooks/useGetDownPaymentInCart';
import { incrementRestoreCacheValue } from '~/modules/checkout/utils/restoreCache';
import { CartInfo, DealInfo, RestoredData } from '~/types/checkout';

type ExtendedAccount = {
  extendedAccountInProgress: boolean;
  setExtendedAccountInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  createLead: (
    formData?: Record<string, string | Date | undefined | null>
  ) => Promise<void>;
};

const ExtendedAccountContext = createContext<ExtendedAccount>(
  {} as ExtendedAccount
);

type ExtendedAccountProps = {};

// Temporary context for the experiment.
// Should be converted to state management system like zustand in the future
export const ExtendedAccountProvider: React.FC<ExtendedAccountProps> = ({
  children,
}) => {
  const router = useRouter();
  const { locale } = useCurrentLocale();
  const [extendedAccountInProgress, setExtendedAccountInProgress] =
    useState(false);
  const [redirected, setRedirected] = useState(false);
  const [responseDealInfo, setResponseDealInfo] = useState<DealInfo>();
  const { setReRenderKey, setShouldAutoScroll, ...checkoutData } =
    useContext(CheckoutContext);
  const cartInfo = checkoutData.cartInfo as CartInfo;
  const { hasValidDownPaymentInCartUrl } = useGetDownPaymentInCart();
  const track = useTrackingStore((state) => state.track);

  const { restoredData }: { restoredData?: RestoredData } = useGetDealData(
    responseDealInfo && !redirected ? CheckoutStep.CONTACT : undefined,
    responseDealInfo
  );
  const hasRestoredData = !!restoredData;

  useEffect(() => {
    if (redirected) {
      return;
    }
    if (!hasRestoredData) return;
    setReRenderKey?.((prev) => prev + 1);
    setRedirected(true);
    goToNextCheckoutStep(
      router,
      CheckoutStep.CONTACT,
      {
        ...responseDealInfo,
        isDirectCheckout: false,
      },
      true
    ).then(() => {
      setExtendedAccountInProgress(false);
    });
  }, [router, hasRestoredData, setReRenderKey, redirected, responseDealInfo]);

  const createLead = useCallback(
    async (formData: Record<string, string | Date>) => {
      setShouldAutoScroll(true);
      const { firstname, lastname, phone, birthday, email } = formData || {};
      const contactValues = {
        contact: {
          firstname: firstname as string,
          lastname: lastname as string,
          phone: phone as string,
          birthday: birthday as Date,
          email: email as string,
        },
      };
      const {
        status,
        hash,
        contact,
        deal,
        requestId,
        data: errorData,
      } = await submitLeadContact(
        {
          ...contactValues,
          downpaymentSelected: hasValidDownPaymentInCartUrl,
        },
        cartInfo,
        locale
      );
      if (status < 200 || status > 299) {
        checkoutErrorEvent(
          errorMessages.DEAL_CREATION_FAILED,
          'cart',
          JSON.stringify(errorData),
          status,
          requestId
        );
        setExtendedAccountInProgress(false);

        return;
      }

      const dealInfo: DealInfo = {
        hash,
        contactId: contact.id,
        dealId: deal.id,
      };

      scrollToTopWithoutAnimation();

      track(TrackingEventName.CHECKOUT_STARTED, {
        additionalProps: {
          ...ECCOMMERCE_EVENTS_PROPERTIES[TrackingEventName.CHECKOUT_STARTED],
        },
      });
      track(TrackingEventName.CHECKOUT_LEAD, {});
      track(TrackingEventName.LEAD_CREATED, {});

      setClientJSONCookie(CookieKeys.LAST_DEAL_INFO, dealInfo);
      setClientCookie(CookieKeys.LAST_DEAL_ID, String(deal.id));
      incrementRestoreCacheValue();
      setResponseDealInfo(dealInfo);
    },
    [track, cartInfo, locale, setResponseDealInfo, setShouldAutoScroll]
  );

  return (
    <ExtendedAccountContext.Provider
      value={{
        extendedAccountInProgress,
        setExtendedAccountInProgress,
        createLead,
      }}
    >
      {children}
    </ExtendedAccountContext.Provider>
  );
};
export default ExtendedAccountContext;
