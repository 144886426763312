import { Button } from '@finn/ui-components';
import { ButtonProps, makeStyles, Theme } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    height: theme.spacing(7),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'initial',
      minWidth: '180px',
    },
  },
}));
type Props = ButtonProps & {
  loading?: boolean;
  title?: string;
  isConfirmationStap?: boolean;
};
const ContinueButton: React.FunctionComponent<Props> = ({
  loading,
  title,
  className,
  isConfirmationStap,
  ...rest
}) => {
  const classes = useStyles();
  const i18n = useIntl();

  const buttonTitle =
    title ??
    i18n.formatMessage({
      id: isConfirmationStap
        ? 'checkout.pageData.confirmation_button'
        : 'checkout.pageData.next_step_button',
    });

  return (
    <>
      <Button
        data-cy="submit"
        loading={loading}
        label={buttonTitle}
        className={cn(classes.button, className)}
        {...rest}
      />
    </>
  );
};
export default ContinueButton;
