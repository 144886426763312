import { shouldShowFieldError, useCurrentLocale } from '@finn/ui-utils';
import { FormHelperText, TextFieldProps } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { PhoneInput } from 'react-international-phone-field';
import { useIntl } from 'react-intl';

import { usePhoneInputStyle } from '~/components/FormElements/usePhoneInputStyle';

// interface can not extend a union-type
type Props = TextFieldProps & {
  form: UseFormReturn;
};

export const HookFormPhoneInput: React.FunctionComponent<Props> = ({
  placeholder,
  label,
  form,
  name,
}) => {
  const classes = usePhoneInputStyle();
  const i18n = useIntl();
  const { region } = useCurrentLocale();

  // phone input library automatically adds and removes space parentheses etc.
  // So we need a custom handler to update the form value, cant use the input field directly
  const handleChange = useCallback(
    (newValue, meta) => {
      const hasUserInput = newValue !== `+${meta?.country?.dialCode}`;
      form?.setValue?.(name, newValue, {
        shouldValidate: hasUserInput,
        shouldDirty: hasUserInput,
        shouldTouch: hasUserInput,
      });
    },
    [form, name]
  );

  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <div className={classes.root}>
            <PhoneInput
              defaultCountry={(region || '').toLowerCase()}
              value={field.value}
              name={field.name}
              placeholder={placeholder}
              onChange={handleChange}
              inputProps={{
                autoComplete: 'tel',
                name: field.name,
              }}
              preferSelectedCountry
            />
            <span className={classes.label}>{label}</span>
            {shouldShowFieldError(fieldState, form.formState) && (
              <FormHelperText error>
                {i18n.formatMessage({
                  id: fieldState?.error?.message?.includes('Invalid Date')
                    ? 'yup.validDate'
                    : fieldState?.error?.message,
                })}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
};
