import { ButtonBase, makeStyles } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

import { LoginScreen, useLoginStore } from './store/useLoginStore';

const useStyles = makeStyles(() => ({
  button: {
    textDecoration: 'underline',
    fontSize: '16px',
  },
}));

export const SwitchToLogin: React.FC = () => {
  const classes = useStyles();
  const i18n = useIntl();
  const alreadyHaveAccount = i18n.formatMessage({
    id: 'userAccount.registration.alreadyHaveAccount',
  });
  const logIn = i18n.formatMessage({
    id: 'userAccount.login.subtitle',
  });
  const loginScreen = useLoginStore((state) => state.loginScreen);
  const setLoginScreen = useLoginStore((state) => state.setLoginScreen);
  const shouldShowRegistration = loginScreen === LoginScreen.registrationForm;

  const onSwitch = () => {
    setLoginScreen(LoginScreen.emailCheckForm);
  };

  if (!shouldShowRegistration) {
    return null;
  }

  return (
    <div className="mt-1 inline-flex items-center gap-2">
      <div className="body-16-light">{alreadyHaveAccount}</div>
      <ButtonBase
        className={classes.button}
        onClick={onSwitch}
        data-testid="switch-to-login"
      >
        {logIn}
      </ButtonBase>
    </div>
  );
};
