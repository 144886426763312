import { showToast } from '@finn/atoms';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { isMobileApp } from '@finn/ui-utils';
import axios from 'axios';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { LoginScreen, useLoginStore } from '../store/useLoginStore';

export const useMagicLinkHandler = (withResetLink = false) => {
  const form = useFormContext();
  const i18n = useIntl();
  const magicLinkSentCount = useLoginStore((state) => state.magicLinkSentCount);
  const setMagicLinkSentCount = useLoginStore(
    (state) => state.setMagicLinkSentCount
  );
  const setLoginScreen = useLoginStore((state) => state.setLoginScreen);
  const track = useTrackingStore((state) => state.track);

  return useCallback(async () => {
    const formData = form.getValues();
    const isApp = isMobileApp();

    await axios.post('/api/auth/custom/magicLink', {
      email: formData.email,
      nextUrl: window.location.href.includes('authv2')
        ? '/'
        : window.location.href,
      origin: window.location.origin,
      isApp,
      withResetLink,
    });
    setLoginScreen(
      withResetLink
        ? LoginScreen.forgotPasswordSuccess
        : LoginScreen.magicLinkSuccess
    );
    if (!isApp) {
      sessionStorage.setItem('magicLinkSent', 'yes');
    }
    if (magicLinkSentCount) {
      showToast({
        status: 'success',
        description: i18n.formatMessage({
          id: 'userAccount.magicLinkModal.sent.resentToast',
        }),
      });
    }
    track(
      magicLinkSentCount
        ? withResetLink
          ? TrackingEventName.FORGOT_PASSWORD_FORM_RE_SUBMITTED
          : TrackingEventName.MAGIC_LINK_FORM_RE_SUBMITTED
        : withResetLink
          ? TrackingEventName.FORGOT_PASSWORD_FORM_SUBMITTED
          : TrackingEventName.MAGIC_LINK_FORM_SUBMITTED,
      {
        additionalProps: {
          values: formData,
          retryCount: magicLinkSentCount,
        },
      }
    );
    setMagicLinkSentCount(magicLinkSentCount + 1);
  }, [
    form,
    i18n,
    magicLinkSentCount,
    setLoginScreen,
    setMagicLinkSentCount,
    withResetLink,
  ]);
};
