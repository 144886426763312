import { Box } from '@material-ui/core';
import React from 'react';

import { MagicLinkButton } from './MagicLinkButton';
import { SocialLoginButton } from './SocialLoginButton';

export type SocialLoginMethod = 'Google' | 'Apple';
type Props = {
  socialButtonSeparator: string;
};

const loginMethods: SocialLoginMethod[] = ['Google', 'Apple'];

export const SocialLoginButtons: React.FC<Props> = ({
  socialButtonSeparator,
}) => {
  return (
    <Box mb={1} mt={1}>
      <Box textAlign="center" mb={3} className="finn-social-button-separator">
        {socialButtonSeparator}
      </Box>
      {loginMethods.map((item) => (
        <Box mb={2} key={item}>
          <SocialLoginButton method={item} />
        </Box>
      ))}
      <Box mb={2}>
        <MagicLinkButton />
      </Box>
    </Box>
  );
};
