import { Modal, ModalContent } from '@finn/atoms/Modal';
import { useIsMobileAndTablet } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import { Login } from '~/modules/login/Login';
import {
  LoginScreen,
  useLoginStore,
} from '~/modules/login/store/useLoginStore';

type Props = {
  open: boolean;
  onClose(): void;
  preventClosing?: boolean;
  fullScreen?: boolean;
  disableAnimation?: boolean;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },
  },
  fullScreenRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '80vh',
  },
}));

export const LoginModal: React.FC<Props> = ({
  open,
  onClose,
  preventClosing,
  fullScreen,
  className,
  disableAnimation,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobileAndTablet();
  const loginScreen = useLoginStore((state) => state.loginScreen);
  const isRegistrationModal = loginScreen === LoginScreen.registrationForm;

  return (
    <Modal
      size="md"
      open={open}
      onClose={onClose}
      scrollAll
      disableBrowserHistory
      preventClosing={preventClosing}
      fullScreen={fullScreen || (isMobile && isRegistrationModal)}
      disableAnimation={disableAnimation}
    >
      <ModalContent>
        <div
          className={cn(
            {
              [classes.root]: !fullScreen,
              [classes.fullScreenRoot]: fullScreen,
            },
            className
          )}
        >
          <Login />
        </div>
      </ModalContent>
    </Modal>
  );
};
