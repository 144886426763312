import { accountLoggedIn, DeprecatedOrigin } from '@finn/cp-utils';
import { checkAccountSwitch, login, useLoginFormTracker } from '@finn/ua-auth';
import { ModalKey, useCloseModal } from '@finn/ua-modals';
import { getSession, useCurrentLocale } from '@finn/ui-utils';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { useLoginStore } from '../store/useLoginStore';

export const useLoginHandler = () => {
  const { isUSA } = useCurrentLocale();
  const { trackSubmitClick, trackFailure } = useLoginFormTracker();
  const form = useFormContext();
  const setServerErrorCode = useLoginStore((state) => state.setServerErrorCode);
  const setFormValues = useLoginStore((state) => state.setFormValues);
  const setSubmittedLogin = useLoginStore((state) => state.setSubmittedLogin);
  const closeModal = useCloseModal();

  return useCallback(async () => {
    setFormValues(form?.getValues() || {});
    const email = form?.getValues('email') || '';
    const password = form?.getValues('password') || '';
    setServerErrorCode(null);
    trackSubmitClick({ source: 'unified-login' });
    // wait for hubspot form to be submitted as we want this to be the first contact with hubspot
    setSubmittedLogin(true);
    const result = await login(email, password);
    if (result.ok) {
      const session = await getSession();
      closeModal(ModalKey.LOGIN);
      if (session) {
        accountLoggedIn({
          contact_id: session.user.hs_contact_id || '',
          origin: DeprecatedOrigin.CHECKOUT as any,
        });
        await checkAccountSwitch();
      }
    } else {
      trackFailure(result.error, { source: 'unified-login' });
      setServerErrorCode(result.error);
    }
    setSubmittedLogin(false);
  }, [
    setFormValues,
    form,
    setServerErrorCode,
    trackSubmitClick,
    setSubmittedLogin,
    isUSA,
    trackFailure,
  ]);
};
